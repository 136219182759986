<template>
  <div>
    <v-layout row>
      <addButton
        v-if="
          userIsAdministrator ||
          $store.getters.hasUserRoleForPageItem({
            role: 1,
          })
        "
        class="add-button"
        :toRoute="{ name: 'editPageAddItem' }"
        :label="$t('Add item')"
      />
      <v-btn
        v-if="
          userIsAdministrator ||
          (page.AllowExportForAllUsers &&
            $store.getters.hasUserRoleForPageItem({
              role: 0,
            }))
        "
        class="mb-4 ml-0 mr-3 add-button"
        color="info"
        @click="exportDialog = true"
      >
        {{ $t("Export items") }}
        <v-icon right>mdi-arrow-top-right</v-icon>
      </v-btn>
      <v-btn
        v-if="
          userIsAdministrator ||
          (page.AllowBulkUpdateForAllUsers &&
            $store.getters.hasUserRoleForPageItem({
              role: 1,
            }))
        "
        class="mb-4 ml-0 mr-3 add-button"
        color="warning"
        @click="bulkUpdateDialog = true"
      >
        {{ $t("Bulk update") }}
        <v-icon right>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        v-if="userIsAdministrator"
        class="mb-4 ml-0 mr-3 add-button"
        color="error"
        @click="deleteAllDialog = true"
      >
        {{ $t("Delete all") }}
        <v-icon right>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="userIsAdministrator"
        class="mb-4 ml-0 mr-3 add-button"
        color="info"
        @click="syncDialog = true"
      >
        {{ $t("Sync items") }}
        <v-icon right>mdi-autorenew</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <template v-if="userIsAdministrator && !page.Custom">
        <editmode-switch classes="mt-0" :name="$t('Edit mode')" />
      </template>
    </v-layout>
    <v-dialog v-model="exportDialog" scrollable max-width="400px">
      <v-card>
        <v-card-title>{{ $t("Export items") }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5" style="height: 500px">
          <p class="mb-3">
            {{ $t("Select the items you wish to export.") }}
          </p>
          <v-form>
            <template v-for="(item, index) in exportItems">
              <v-checkbox
                class="mt-0 font-weight-bold"
                :key="'exportItems' + index"
                v-model="item.selected"
                :label="item.title"
              ></v-checkbox>
              <v-checkbox
                class="mt-0 ml-5"
                v-for="(linkedItem, linkedItemIndex) in item.linkedExportItems"
                v-bind:key="'linkedExportItem' + linkedItemIndex + index"
                v-model="linkedItem.selected"
                :label="linkedItem.title"
              ></v-checkbox>
            </template>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="exportDialog = false">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="exportPageContent()">
            {{ $t("Export") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="bulkUpdateDialog" scrollable max-width="600px">
      <v-card>
        <v-card-title>{{ $t("Bulk update") }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-form>
            <h3>
              {{ $t("Select the property you wish to update.") }}
            </h3>

            <v-autocomplete
              :items="bulkUpdateItems"
              v-model="selectedBulkUpdateProperty"
              item-text="Title"
              return-object
              class="mb-3 mt-0"
            ></v-autocomplete>

            <h3 class="mb-3">
              {{ $t("Enter or select the new value") }}
            </h3>
            <pageElement
              v-if="selectedBulkUpdateProperty != null"
              :item="selectedBulkUpdateProperty"
              :websiteId="page.WebsiteId.$oid"
              :pageId="page._id.$oid"
              ref="pageElement"
            />
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="bulkUpdateDialog = false">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="bulkUpdatePageContent()"
            :loading="bulkUpdatnigContent"
          >
            {{ $t("Bulk update") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteAllDialog" scrollable max-width="600px">
      <v-card>
        <v-card-title>{{ $t("Delete all") }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <p v-if="checkDelDisable">
            {{ $t("No items to delete!") }}
          </p>
          <p v-else>
            {{ $t("Are you sure you want to delete all items?") }}
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="deleteAllDialog = false">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            color="error darken-1"
            text
            v-on:click="deleteAll()"
            :loading="deleteAllContent"
            :disabled="checkDelDisable"
          >
            {{ $t("Delete all") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="syncDialog" persistent scrollable max-width="600px">
      <v-card>
        <v-card-title>{{ $t("Sync all items") }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <p>{{ $t("Are you sure you want to sync all items?") }}</p>
          <v-row no-gutters v-if="processingSync">
            <v-col cols="12">
              <strong>{{
                `${toSyncItems.done} ${$t("of the")} 
                        ${toSyncItems.amount} ${$t("records")}`
              }}</strong>
            </v-col>
            <v-col cols="12">
              <v-progress-linear v-model="toSyncItems.percentage" height="16">
              </v-progress-linear>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="processingSync" text @click="syncDialog = false">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            color="info darken-1"
            text
            v-on:click="syncAllItems()"
            :loading="processingSync"
            :disabled="page.ContentCount == 0"
          >
            {{ $t("Sync items") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    tableSortBy: {
      type: String,
      default: "",
    },
    tableSortDescending: {
      type: Boolean,
    },
    page: {
      type: [Object, Array],
    },
    linkedPages: {
      type: [Object, Array],
    },
    searchValue: {
      type: String,
    },
    content: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      userIsAdministrator: this.$auth.userIsAdministrator(),
      exportDialog: false,
      bulkUpdateDialog: false,
      bulkUpdatnigContent: false,
      selectedBulkUpdateProperty: null,
      deleteAllDialog: false,
      syncDialog: false,
      deleteAllContent: false,
      processingSync: false,
      toSyncItems: { amount: 0, done: 0, percentage: 0 },
    };
  },

  computed: {
    checkDelDisable() {
      if (!this.content) {
        return true;
      }
      if (this.content.length == 0) {
        return true;
      }
      return false;
    },
    exportItems() {
      var exportItems = this.getExportItemsFromPage(this.page);
      if (this.linkedPages) {
        for (var value in this.page.ContentDefinition) {
          if (this.page.ContentDefinition[value].LinkedPage) {
            var linkedPage = this.linkedPages.filter((obj) => {
              return (
                obj._id.$oid ==
                this.page.ContentDefinition[value].LinkedPage.$oid
              );
            })[0];
            if (linkedPage) {
              var index = exportItems.findIndex((obj) => obj.value == value);
              exportItems[index].linkedExportItems =
                this.getExportItemsFromPage(linkedPage, true);
            }
          }
        }
      }
      return exportItems;
    },
    bulkUpdateItems() {
      if (this.page.RepeatingContent) {
        var items = [];
        for (var value in this.page.ContentDefinition) {
          if (
            this.page.ContentDefinition[value].Type != "image" &&
            this.page.ContentDefinition[value].Type != "croppedimage" &&
            this.page.ContentDefinition[value].Type != "gallery" &&
            this.page.ContentDefinition[value].Type != "files"
          ) {
            items.push(this.page.ContentDefinition[value]);
          }
        }
        return items;
      }
      return [];
    },
  },
  methods: {
    getExportItemsFromPage(page, isLinkedPageItem) {
      if (page.RepeatingContent) {
        var items = [];
        for (var value in page.ContentDefinition) {
          if (
            page.ContentDefinition[value].Type != "image" &&
            page.ContentDefinition[value].Type != "croppedimage" &&
            page.ContentDefinition[value].Type != "gallery" &&
            page.ContentDefinition[value].Type != "files" &&
            (page.ContentDefinition[value].Type != "content" ||
              !isLinkedPageItem)
          ) {
            items.push({
              title: page.ContentDefinition[value].Title,
              value: value,
              selected: false,
            });
          }
        }
        return items;
      }
      return [];
    },

    deleteAll() {
      this.deleteAllContent = true;
      this.$request.delete(
        "/page-content/" + this.$route.params.id,
        null,
        (res) => {
          this.deleteAllContent = false;
          this.deleteAllDialog = false;
          if (res.success) {
            this.$emit("contentReset");
          } else {
            this.$store.dispatch("setError", res);
          }
        }
      );
    },

    getAmountCounter(count) {
      if (count < 1000) {
        return 100;
      } else if (count < 2000) {
        return 250;
      } else if (count < 5000) {
        return 500;
      } else {
        return 1000;
      }
    },
    syncAllItems() {
      this.$request.get(
        "/pagecontentcount/" + this.$route.params.id,
        null,
        (count) => {
          if (count.success) {
            const apiCounterAmount = this.getAmountCounter(count.data);

            let processLength = Math.ceil(count.data / apiCounterAmount);
            this.toSyncItems.done = 0;
            this.toSyncItems.amount = count.data;
            this.toSyncItems.percentage = 0;
            this.processingSync = true;
            let masterdata = Object.assign(this.page.ContentDefinition);

            let requests = [];
            for (let i = 0; i < processLength; i++) {
              let itemsLength = i * apiCounterAmount;
              requests.push(
                "/pagecontent/" +
                  this.$route.params.id +
                  "?start=" +
                  itemsLength +
                  "&count=" +
                  apiCounterAmount +
                  "&sortDescending=false" +
                  "&language=" +
                  this.currentLanguage
              );
            }
            this.$request.getAll(requests, null, (res) => {
              const resLenght = res.length;
              for (let i = 0; i < resLenght; i++) {
                let contentDefinition = [];
                res[i].data.forEach((element) => {
                  let prototypeObj = Object.assign({});

                  const keysToplvlItems = Object.keys(element);
                  const dataKeys = Object.keys(element.Data);

                  //loop through data of top levl items
                  keysToplvlItems.forEach((keyOtherItems) => {
                    if (keyOtherItems == "Data") {
                      prototypeObj[keyOtherItems] = {};
                      const datakeysItems = Object.keys(masterdata);
                      datakeysItems.forEach((el) => {
                        if (!dataKeys.includes(el)) {
                          prototypeObj.Data[el] = masterdata[el];
                        } else {
                          prototypeObj.Data[el] = element.Data[el];
                        }
                      });
                    } else if (keyOtherItems == "DisplayLabel") {
                      prototypeObj[keyOtherItems] = element[keyOtherItems];
                    } else if (!prototypeObj[keyOtherItems]) {
                      prototypeObj[keyOtherItems] = element[keyOtherItems];
                    }
                  });

                  //loop through data of the Data object in the element
                  dataKeys.forEach((dataKey) => {
                    let dummyObj = Object.assign({});
                    dummyObj[dataKey] = {};

                    //check if element inside both obj
                    if (
                      Object.prototype.hasOwnProperty.call(masterdata, dataKey)
                    ) {
                      const items = Object.keys(masterdata[dataKey]);
                      const itemsData = Object.keys(element.Data[dataKey]);
                      //loop through masterdatakeys to check difference
                      items.forEach((item) => {
                        const itemX = JSON.stringify(
                            element.Data[dataKey][item]
                          ),
                          itemY = JSON.stringify(masterdata[dataKey][item]);
                        if (itemX !== itemY) {
                          if (item == "Value" || item == "ValueSort") {
                            //special case for files --> makes sure the data changes to een obj {Nl:}
                            if (
                              masterdata[dataKey].TranslatedFiles !==
                              element.Data[dataKey].TranslatedFiles
                            ) {
                              if (
                                element.Data[dataKey].Type == "files" &&
                                masterdata[dataKey].TranslatedFiles &&
                                element.Data[dataKey][item]
                              ) {
                                if (
                                  Array.isArray(element.Data[dataKey][item])
                                ) {
                                  dummyObj[dataKey][item] = element.Data[
                                    dataKey
                                  ][item]
                                    ? { Nl: [...element.Data[dataKey][item]] }
                                    : "";
                                } else {
                                  dummyObj[dataKey][item] = element.Data[
                                    dataKey
                                  ][item]
                                    ? element.Data[dataKey][item]["Nl"] ||
                                      element.Data[dataKey][item]["Fr"] ||
                                      element.Data[dataKey][item]["En"]
                                    : "";
                                }
                              } else if (
                                element.Data[dataKey].Type == "files" &&
                                !masterdata[dataKey].TranslatedFiles &&
                                element.Data[dataKey][item]
                              ) {
                                dummyObj[dataKey][item] = element.Data[dataKey][
                                  item
                                ]
                                  ? element.Data[dataKey][item]["Nl"]
                                  : "";
                              } else {
                                dummyObj[dataKey][item] = element.Data[dataKey][
                                  item
                                ]
                                  ? element.Data[dataKey][item]
                                  : "";
                              }
                            }
                          } else {
                            dummyObj[dataKey][item] = masterdata[dataKey][item];
                          }
                        } else {
                          dummyObj[dataKey][item] = element.Data[dataKey][item];
                        }
                      });
                      itemsData.forEach((item) => {
                        if (
                          !Object.prototype.hasOwnProperty.call(
                            dummyObj[dataKey],
                            item
                          )
                        ) {
                          /* if (
                            item == "Value" ||
                            item == "ValueSort" 
                          ) { */
                          dummyObj[dataKey][item] = element.Data[dataKey][item]
                            ? element.Data[dataKey][item]
                            : "";
                          /* } */
                        }
                      });

                      prototypeObj.Data[dataKey] = dummyObj[dataKey];
                    }
                  });

                  contentDefinition.push(prototypeObj);
                });
                //put array items of call to request

                //Generate display labels
                for (var ii = 0; ii < contentDefinition.length; ii++) {
                  contentDefinition[ii].DisplayLabel =
                    this.$helpers.getDisplayLabel(
                      contentDefinition[ii],
                      this.page.DisplayLabelCustomFormat
                    );
                  const langs = this.$config.contentLanguages;
                  if (!contentDefinition[ii].DisplayLabels) {
                    contentDefinition[ii].DisplayLabels = {};
                  }
                  langs.forEach((lang) => {
                    contentDefinition[ii].DisplayLabels[lang] =
                      this.$helpers.getDisplayLabel(
                        contentDefinition[ii],
                        this.page.DisplayLabelCustomFormat,
                        lang
                      );
                  });
                }
                this.$request.put(
                  "/contentlist",
                  contentDefinition,
                  (result) => {
                    if (result.success) {
                      this.toSyncItems.done += res[i].data.length;
                      this.toSyncItems.percentage =
                        (this.toSyncItems.done / this.toSyncItems.amount) * 100;
                      if (this.toSyncItems.done == this.toSyncItems.amount) {
                        //getPage --> does refresh of data --> computed handels the visualization
                        this.$emit("getData");
                        this.syncDialog = false;
                        this.processingSync = false;
                      }
                    } else {
                      this.$store.dispatch("setError", res);
                    }
                  }
                );
              }
            });
          } else {
            this.$store.dispatch("setError", count);
          }
        }
      );
    },

    bulkUpdatePageContent() {
      this.bulkUpdatnigContent = true;
      var propertyName = "";
      for (var value in this.page.ContentDefinition) {
        if (
          this.page.ContentDefinition[value].Title ==
          this.selectedBulkUpdateProperty.Title
        ) {
          propertyName = value;
        }
      }
      var queryString =
        "?sortBy=" +
        this.tableSortBy +
        "&sortDescending=" +
        this.tableSortDescending +
        "&search=" +
        (this.searchValue == null ? "" : this.searchValue) +
        this.getPageFiltersQueryString() +
        "&propertyName=" +
        propertyName;
      this.$request.put(
        "/bulkupdatecontent/" + this.page._id.$oid + queryString,
        this.selectedBulkUpdateProperty,
        (res) => {
          if (res.success) {
            if (
              this.page.SynchronizationBulkPutUrl &&
              this.page.SynchronizationBulkPutUrl.length > 0
            ) {
              this.$request.put(
                this.page.SynchronizationBulkPutUrl +
                  "/" +
                  this.page._id.$oid +
                  queryString,
                this.selectedBulkUpdateProperty,
                (res) => {
                  if (!res.success) {
                    this.$store.dispatch("setError", res);
                  }
                }
              );
            }
            this.$emit("contentReset");
            this.$emit("getContent");
            this.bulkUpdateDialog = false;
            this.bulkUpdatnigContent = false;
            this.success = res.message;
          } else {
            this.bulkUpdateDialog = false;
            this.bulkUpdatnigContent = false;
            this.$store.dispatch("setError", res);
          }
        }
      );
    },
    exportPageContent() {
      this.exportDialog = false;

      var fieldsQueryString = "";
      for (var item in this.exportItems) {
        if (this.exportItems[item].selected) {
          fieldsQueryString += "fields=" + this.exportItems[item].value + "&";
        }
        for (var linkedItem in this.exportItems[item].linkedExportItems) {
          if (this.exportItems[item].linkedExportItems[linkedItem].selected) {
            fieldsQueryString +=
              "fields=" +
              this.exportItems[item].value +
              "." +
              this.exportItems[item].linkedExportItems[linkedItem].value +
              "&";
          }
        }
      }

      var queryString =
        "?sortBy=" +
        this.tableSortBy +
        "&sortDescending=" +
        this.tableSortDescending +
        "&search=" +
        (this.searchValue == null ? "" : this.searchValue) +
        this.getPageFiltersQueryString() +
        fieldsQueryString;

      this.$request.download(
        "/exportcontent/" + this.page._id.$oid + queryString,
        { responseType: "blob" },
        "export_ " + this.page._id.$oid + ".xlsx"
      );
    },
    getPageFiltersQueryString() {
      var filters = "";
      for (var filter in this.pageFilters) {
        if (
          this.pageFilters[filter] != null &&
          this.pageFilters[filter] != undefined
        ) {
          if (Array.isArray(this.pageFilters[filter])) {
            for (var currentFilterIndex in this.pageFilters[filter]) {
              filters +=
                "filters=" +
                filter +
                ":" +
                this.pageFilters[filter][currentFilterIndex]._id.$oid +
                "&";
            }
          } else if (this.pageFilters[filter].$oid) {
            filters +=
              "filters=" + filter + ":" + this.pageFilters[filter].$oid + "&";
          } else {
            filters +=
              "filters=" + filter + ":" + this.pageFilters[filter] + "&";
          }
        }
      }
      return "&" + filters;
    },
    setDialog() {
      this.syncDialog = true;
    },
  },
};
</script>
<style scoped>
@media only screen and (max-width: 568px) {
  .add-button {
    width: 100%;
  }
}
</style>
